


<template>
    <v-container>
    <v-row>

        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>{{header}}</h2>
                <v-alert type="error"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertError.show"
                         @click="navBack()"
                >
                    {{alertError.text}}
                </v-alert>
                <v-alert type="warning"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertWarning.show"
                         @click="navBack()"
                >
                    {{alertWarning.text}}
                </v-alert>
                <v-btn v-if="navBackShow" block
                       color="primary"
                       @click.prevent="navBack()"
                       elevation="10"
                >
                    Обратно
                </v-btn>
                <v-card
                        class="mt-6 pa-2"
                        elevation="10"
                        outlined
                        :loading="loading"
                >


                    <v-list-item-group
                            shaped
                            color="primary"
                            v-model="selectedItem"
                    >
                        <v-list-item
                                v-for="(e,i) in arr"
                                :key="i"
                                @click="onNext(i)"
                                :class="{disabled: e.FREE=='0'}"
                                :disabled="e.FREE=='0'"
                        >
                            <v-list-item-icon>
                                <v-icon v-if="e.FREE=='0'"
                                        color="gray"
                                >
                                    {{iconNo}}
                                </v-icon>
                                <v-icon v-else
                                        color="green"
                                >
                                    {{iconYes}}
                                </v-icon>

                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="e.NNAME"></v-list-item-title>
                                <v-list-item-subtitle v-text="e.REM"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-card>
            </v-card>
        </v-col>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>Выбрано</h2>
                <v-card
                        class="mt-6 pa-2"
                        elevation="0"
                        outlined
                >


                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-html="LPU.name"/>
                            <v-list-item-subtitle v-text="LPU.address"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="FIN.name"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="AGE.name"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="MEDG.PANELCAPTION1.length>0">
                        <v-list-item-content>
                            <v-list-item-title v-text="MEDG.PANELCAPTION1"/>
                            <v-list-item-subtitle v-text="MEDG.PANELCAPTION2"/>
                        </v-list-item-content>
                    </v-list-item>


                </v-card>
            </v-card>
        </v-col>

    </v-row>
    </v-container>


</template>

<style scoped>

    .disabled {
        pointer-events: none;
        text-decoration: line-through;
        color: grey;
    }
</style>

<script>
    import post, {postLog} from '../tools/post'
    import {toBack, toNext,thisLog} from '@/components/app/js/globals'
    import {mdiAccountCheck , mdiAccountCancel} from '@mdi/js';
    export default {
        name: 'Med',
        data: () => ({
            name: 'Med',
            header: 'Выберите врача',
            loading: true,
            log: true,
            alert: true,
            selectedItem: null,
            alertError: {
                show: false,
                text: null
            },
            alertWarning: {
                show: false,
                text: null
            },
            iconYes: mdiAccountCheck ,
            iconNo: mdiAccountCancel,
            arr: [
                /*
                {
                    FREE : '1',
                    NNAME : 'Вахрушев А.В. ( каб 220)  ПЕДИАТР', //Upper, т.к. так передается из REST API
                    NPP: '595',
                    REM: 'Есть талоны с 02.11.2020 08:00'
                }

                 */
            ]
        }),
        computed: {
            LPU() {
                return this.$store.getters.LPU;
            },
            FIN() {
                return this.$store.getters.FIN;
            },
            AGE() {
                return this.$store.getters.AGE;
            },
            MEDG() {
                return this.$store.getters.MEDG;
            },
            MED() {
                return this.$store.getters.MED;
            },
            is_actual() {
                return (this.$store.getters.LPU.actual && this.$store.getters.LPU.actual==='1') &&
                       (this.$store.getters.FIN.actual && this.$store.getters.FIN.actual==='1') &&
                       (this.$store.getters.AGE.actual && this.$store.getters.AGE.actual==='1') &&
                       (this.$store.getters.MEDG.actual && this.$store.getters.MEDG.actual==='1')
            },
            navBackShow() {
                //console.log('-----',this.alertError.show, this.alertWarning.show)
                return (this.alertError.show || this.alertWarning.show)
            }

        },
        async beforeMount() {
            try {
                //console.log('before Mount')
                if (this.is_actual) {
                    //console.log(this.LPU)
                    const url = this.$store.getters.LPU.resturl + '/getmed'
                    thisLog(this, ['url', url])
                    const data = {
                        panelname: this.MEDG.PANELNAME,
                        free: '',
                        nppkard: '',
                        opt: this.FIN.opt,
                        db: '',
                        de: '',
                        tb: '',
                        te: '',
                        nppfilial: this.LPU.nppfilial
                    }

                    try {
                        let res = await post(url, data)
                        this.arr = res.data
                        thisLog(this, [' MED >>>>>>>>>>>> ', this.arr])
                        if (this.arr.length == 0) {
                            const _MEDG=this.$store.getters.MEDG
                            this.alertWarning.text = `Не найдено данных по врачам из группы "${_MEDG.PANELCAPTION1} ${_MEDG.PANELCAPTION2}"`
                            this.alertWarning.show = true
                            //pushBack(this,{nodata:true,prev:true})
                        }
                    } catch (err) {
                        this.alertError.text = err
                        this.alertError.show = true
                        postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Med#1' } )
                        //pushErr(this, err)
                    }
                } else {
                    this.alertWarning.text = "Не найдено данных - повторите попытку еще раз"
                    this.alertWarning.show = true
                    toBack(this)  //каскадный откат
                    //pushBack(this,{nodata: true})
                }
                const _MED=this.$store.getters.MED
                this.selectedItem = ( _MED && typeof _MED.selectedItem === 'number' ? _MED.selectedItem : null)

                //подтвердить актуальность selectedItem при переходах!!!
                if( !(typeof this.selectedItem == 'number' && this.selectedItem+1 <= this.arr.length  && this.arr[this.selectedItem].FREE==='1') ){
                    this.$store.dispatch('SET_MED_ACTUAL', '0')
                }

            }catch(e){
                alert(e)
                postLog(this.$store.getters.LPU.resturl, { alert: e, place: 'Med#2' } )
            }
            // заглушка для Gipp
            //console.log('LPU',this.LPU.lpuid, this.arr)
            if(this.LPU.lpuid=='gipp'){
              this.arr=[
                {
                  FREE: '0',
                  NNAME: 'Проценко С.Г. (каб 12)',
                  NPP: '1',
                  REM: 'Свободных талонов нет'
                },
                {
                  FREE: '0',
                  NNAME: 'Валиулин М.В. (каб 7)',
                  NPP: '1',
                  REM: 'Свободных талонов нет'
                },
                {
                  FREE: '0',
                  NNAME: 'Гадеев М.А. (каб 10)',
                  NPP: '1',
                  REM: 'Свободных талонов нет'
                },
              ]
            }
            this.loading=false
        },
        methods: {
            onNext(i){
                try {
                    this.$store.dispatch('SET_MED', {selectedItem: i, ...this.arr[i]})
                    toNext(this)
                }catch(err){
                    this.alertError.text=err
                    this.alertError.show=true
                    postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Med#3' } )
                    //pushErr(this,err)
                }

            },
            async navBack(){
                toBack(this)
            },
        }



    }
</script>
